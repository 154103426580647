import { useState } from 'react';
import useAutoLogin from 'hooks/use-auto-login';
import useYupValidationResolver from 'hooks/use-yup-validation-resolver';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import InputField from 'components/ui-elements/input-field';

import { loginSchema } from 'configs/yup-validation-schemas';

import { asyncLogin } from 'services/reqres/requests';

function Login() {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const resolver = useYupValidationResolver(loginSchema);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver });

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    console.log('onSubmit', data);
    await dispatch(asyncLogin({ user_name: data.user_name, password: data.password }));
    setIsSubmitting(false);
  };

  useAutoLogin(); // will redirect to '/' or '/from-path' if user is logged in

  return (
    <div className="Login flex min-h-full flex-col justify-center items-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Sign in to your account
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form onSubmit={handleSubmit(onSubmit)} className="login-form space-y-6">
          <InputField
            label="Username"
            name="user_name"
            type="text"
            autoComplete={'current-email'}
            error={errors.user_name}
            {...register('user_name')}
          />

          <InputField
            label="Password"
            name="password"
            type="password"
            autoComplete={'current-password'}
            error={errors.password}
            {...register('password')}
          />

          <input
            type="submit"
            value={isSubmitting ? "Signing in..." : "Sign in"}
            className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            disabled={isSubmitting}
          />
        </form>
      </div>
    </div>
  );
}

export default Login;