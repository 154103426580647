import { createSlice } from '@reduxjs/toolkit'

const authState = {
  user: JSON.parse(localStorage.getItem('user')) || null,
  token: localStorage.getItem('token') || null,
}

const authSlice = createSlice({
  name: 'auth',
  initialState: authState,
  reducers: {
    login(state, action) {
      state.user = action.payload.user
      state.token = action.payload.token
      localStorage.setItem('user', JSON.stringify(state.user));
      localStorage.setItem('token', state.token);
    },
    logout(state) {
      state.user = null
      state.token = null
      localStorage.removeItem('user');
      localStorage.removeItem('token');
    },
  },
})

export const { login, logout } = authSlice.actions

export default authSlice.reducer
